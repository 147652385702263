<template>
  <div class="parterpage w">
    <div class="pleft">
      <p class="font14400 black1 lefttop">当前语伴：</p>
      <div class="head">
        <img :src="alpacas[activealpaca].headimg" alt="" />
      </div>
      <p class="font18500 green1 headtitle">{{ alpacas[activealpaca].name }}</p>
      <ul class="navlist font18400 grey2">
        <li
          :class="leftactive == 1 || mouseactive == 1 ? 'activeli' : ''"
          @mouseenter="changeact(1)"
          @mouseleave="mouseactive = 0"
          @click="changeleft(1)"
        >
          <img
            :src="
              leftactive == 1 || mouseactive == 1
                ? require('./images/historyactive.png')
                : require('./images/history.png')
            "
            alt=""
          />
          <p>历史记录</p>
        </li>
        <li
          :class="leftactive == 2 || mouseactive == 2 ? 'activeli' : ''"
          @mouseenter="changeact(2)"
          @mouseleave="mouseactive = 0"
          @click="createNewHuiHua"
        >
          <img
            :src="
              leftactive == 2 || mouseactive == 2
                ? require('./images/createactive.png')
                : require('./images/create (1).png')
            "
            alt=""
          />
          <p>新建会话</p>
        </li>
        <li
          :class="leftactive == 3 || mouseactive == 3 ? 'activeli' : ''"
          @mouseenter="changeact(3)"
          @mouseleave="mouseactive = 0"
        >
          <img
            :src="
              leftactive == 3 || mouseactive == 3
                ? require('./images/stopactive.png')
                : require('./images/stop (1).png')
            "
            alt=""
          />
          <p>停止对话</p>
        </li>
        <li
          :class="leftactive == 4 || mouseactive == 4 ? 'activeli' : ''"
          @click="changeleft(4)"
          @mouseenter="changeact(4)"
          @mouseleave="mouseactive = 0"
        >
          <img
            :src="
              leftactive == 4 || mouseactive == 4
                ? require('./images/changeactive.png')
                : require('./images/change (1).png')
            "
            alt=""
          />
          <p>切换羊驼</p>
        </li>
      </ul>
    </div>
    <div class="rightbox">
      <div class="topbox">
        <div class="dialogtitle">
          <p class="font14400 black1 lefttop">
            当前语伴：<span class="font18500 green1 headtitle">
              {{ alpacas[activealpaca].name }}
            </span>
          </p>
          <img src="./images/more.png" alt="" @click="topnav = !topnav" />
        </div>
        <ul class="navlist font18400 black1" v-show="topnav">
          <li
            :class="leftactive == 1 || mouseactive == 1 ? 'activeli' : ''"
            @mouseenter="changeact(1)"
            @mouseleave="mouseactive = 0"
            @click="changeleft(1)"
          >
            <p>历史记录</p>
          </li>
          <li
            :class="leftactive == 2 || mouseactive == 2 ? 'activeli' : ''"
            @mouseenter="changeact(2)"
            @mouseleave="mouseactive = 0"
            @click="createNewHuiHua"
          >
            <p>新建会话</p>
          </li>
          <li
            :class="leftactive == 3 || mouseactive == 3 ? 'activeli' : ''"
            @mouseenter="changeact(3)"
            @mouseleave="mouseactive = 0"
          >
            <p>停止对话</p>
          </li>
          <li
            v-show="leftactive != 5"
            :class="leftactive == 5 || mouseactive == 5 ? 'activeli' : ''"
            @click="changeleft(5)"
            @mouseenter="changeact(5)"
            @mouseleave="mouseactive = 0"
          >
            <p :class="leftactive == 5 || mouseactive == 5 ? 'activeli' : ''">
              切换羊驼
            </p>
            <img src="./images/down.png" alt="" />
          </li>
          <div class="topalpacabox" v-if="leftactive == 5">
            <div class="justbetten">
              <p
                style="width: 100%; cursor: pointer"
                class="green1"
                @click="changeleft(5)"
              >
                切换羊驼
              </p>
              <div class="alpacaclose">
                <img src="./images/up.png" alt="" @click="changeleft(5)" />
              </div>
            </div>
            <div
              :class="
                activealpaca == 0
                  ? 'alpaca alpacaactive1 alpacafocus1'
                  : 'alpaca alpacaactive1'
              "
              @click="changealpaca(0)"
            >
              <div class="alpacahead">
                <img src="./images/gulibig.png" alt="" />
              </div>
              <div class="alpacaintroduce">
                <div class="alpacaname">
                  <p class="font17500 gulicolor1">Quri·古力</p>
                </div>
                <div class="alpacacha font12500 gulicolor2">
                  <span class="guliback">霸道总裁</span
                  ><span class="guliback">多金</span>
                </div>
                <div class="alpacades font12400 black1">
                  <p>
                    你好，我叫古力，我是一位绅士，沉稳的霸道总裁，也是财富的象征。
                  </p>
                </div>
              </div>
            </div>
            <div
              :class="
                activealpaca == 1
                  ? 'alpaca alpacaactive2 alpacafocus2'
                  : 'alpaca alpacaactive2'
              "
              @click="changealpaca(1)"
            >
              <div class="alpacahead">
                <img src="./images/miski.png" alt="" />
              </div>
              <div class="alpacaintroduce">
                <div class="alpacaname">
                  <p class="font17500 miskicolor1">Miski·蜜思琪</p>
                </div>
                <div class="alpacacha font12500 miskicolor2">
                  <span class="miskiback">仙气飘飘</span
                  ><span class="miskiback">温柔</span>
                  <span class="miskiback">知性</span>
                </div>
                <div class="alpacades font12400 black1">
                  <p>你好，我叫蜜思琪，我是一位治愈女神，温柔且仙气飘飘。</p>
                </div>
              </div>
            </div>
            <div
              :class="
                activealpaca == 2
                  ? 'alpaca alpacaactive3 alpacafocus3'
                  : 'alpaca alpacaactive3'
              "
              @click="changealpaca(2)"
            >
              <div class="alpacahead">
                <img src="./images/sami.png" alt="" />
              </div>
              <div class="alpacaintroduce">
                <div class="alpacaname">
                  <p class="font17500 samicolor1">Sami·萨米</p>
                </div>
                <div class="alpacacha font12500 samicolor2">
                  <span class="samiback">活泼可爱</span
                  ><span class="samiback">乖巧</span>
                  <span class="samiback">童真</span>
                </div>
                <div class="alpacades font12400 black1">
                  <p>
                    你好，我叫萨米，我是一个小朋友，精灵古怪的我可以给你带来好运气。
                  </p>
                </div>
              </div>
            </div>
            <div
              :class="
                activealpaca == 3
                  ? 'alpaca alpacaactive4 alpacafocus4'
                  : 'alpaca alpacaactive4'
              "
              @click="changealpaca(3)"
            >
              <div class="alpacahead">
                <img src="./images/bonito.png" alt="" />
              </div>
              <div class="alpacaintroduce">
                <div class="alpacaname">
                  <p class="font17500 bonitocolor1">Bonito·伯尼托</p>
                </div>
                <div class="alpacacha font12500 bonitocolor2">
                  <span class="bonitoback">坚毅勇敢</span
                  ><span class="bonitoback">文采斐然</span>
                  <span class="bonitoback">理性</span>
                </div>
                <div class="alpacades font12400 black1">
                  <p>你好，我叫伯尼托，风流倜傥，英俊潇洒就是我的代名词。</p>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <div class="pright">
        <div class="dialoguebox">
          <div class="dialoguelist" ref="dialoguelist">
            <div class="dialogueitem itemrobot">
              <div class="dialoghead">
                <img :src="alpacas[activealpaca].headimg" alt="" />
              </div>
              <div class="dialog">
                <p class="font18400 black2">
                  你好，我是{{
                    alpacas[activealpaca].name
                  }}，我是你的专属对话机器人。
                </p>
              </div>
            </div>
            <div
              :class="
                mes.chatRole == 'robot'
                  ? 'dialogueitem itemrobot'
                  : 'dialogueitem itemuser'
              "
              v-for="(mes, mindex) in messagesarr"
              :key="mindex"
            >
              <div class="dialoghead" v-if="mes.chatRole == 'robot'">
                <img :src="alpacas[activealpaca].headimg" alt="" />
              </div>
              <div style="width: 100%" v-if="mes.chatRole == 'xitong'">
                <div class="xitong">
                  <p class="font16400 green1">{{ mes.chatContent }}</p>
                </div>
              </div>
              <div class="dialog" v-else>
                <p class="font18400 black2">
                  {{ mes.chatContent }}
                </p>
              </div>
              <div class="dialoghead" v-if="mes.chatRole == 'user'">
                <img
                  :src="
                    (userInfo && userInfo.avatar) ||
                    require('./images/head.png')
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="classlist">
            <span class="font18400 black1 classnav">试试这样问：</span>
            <span
              class="font18400 grey1 classnav classnavb"
              @click="changefrontques('帮我翻译为西语')"
              >帮我翻译为西语</span
            >
            <span
              class="font18400 grey1 classnav classnavb"
              @click="changefrontques('帮我翻译为中文')"
              >帮我翻译为中文</span
            >
            <!-- <span class="font18400 grey1 classnav classnavb">帮我翻译为西语</span>
          <span class="font18400 grey1 classnav classnavb">帮我翻译为中文</span>
          <span class="font18400 grey1 classnav classnavb">帮我翻译为西语</span>
          <span class="font18400 grey1 classnav classnavb">帮我翻译为中文</span> -->
          </div>
          <el-input
            placeholder="你可以问我任何问题"
            v-model="questionend"
            @keyup.enter.native="getStreamId"
          >
            <!-- <span
            slot="prepend"
            :style="{ padding: frontquestion ? '0 20px' : '' }"
            >{{ frontquestion }}</span
          > -->
            <el-tag
              :style="{ padding: frontquestion ? '0 20px' : '' }"
              slot="prepend"
              v-show="frontquestion"
              :key="frontquestion"
              closable
              @close="closeshishiwen"
            >
              {{ frontquestion }}
            </el-tag>
            <i
              :class="
                questionend.length > 0
                  ? 'el-icon-edit el-input__iconact'
                  : 'el-icon-edit el-input__icon'
              "
              slot="suffix"
              @click="getStreamId"
            >
            </i>
          </el-input>
        </div>
        <div class="historybox" v-if="leftactive == 1">
          <div class="historyclose">
            <img src="./images/close.png" alt="" @click="changeleft(1)" />
          </div>
          <div class="textcontenbox">
            <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="historyparam.chatContent"
              @keyup.enter.native="gethistoryList"
            >
            </el-input>
            <div class="textbox">
              <div class="history" v-for="his in historyList" :key="his.id">
                <div class="historyhead">
                  <img
                    v-if="his.chatRole == 'robot'"
                    :src="alpacas[activealpaca].headimg"
                    alt=""
                  />
                  <img
                    v-if="his.chatRole == 'user'"
                    :src="
                      (userInfo && userInfo.avatar) ||
                      require('./images/head.png')
                    "
                    alt=""
                  />
                </div>
                <div class="historyitem">
                  <p class="itemtitle">
                    <span class="font16400 black1"
                      ><span v-if="his.chatRole == 'robot'">{{
                        alpacas[activealpaca].name
                      }}</span></span
                    >
                    <span class="font16400 grey2">{{ his.chatTime }}</span>
                  </p>
                  <p
                    class="font18400 black1"
                    v-html="
                      brightenKeyword(his.chatContent, historyparam.chatContent)
                    "
                  ></p>
                </div>
              </div>

              <el-pagination
                v-if="historyTotal > historyparam.pageSize"
                background
                layout="prev, pager, next"
                :total="historyTotal"
                prev-text="上一页"
                next-text="下一页"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <div class="alpacabox" v-if="leftactive == 4">
          <div class="alpacaclose">
            <img src="./images/close.png" alt="" @click="changeleft(4)" />
          </div>
          <div
            :class="
              activealpaca == 0
                ? 'alpaca alpacaactive1 alpacafocus1'
                : 'alpaca alpacaactive1'
            "
            @click="changealpaca(0)"
          >
            <div class="alpacahead">
              <img src="./images/gulibig.png" alt="" />
            </div>
            <div class="alpacaintroduce">
              <div class="alpacaname">
                <p class="font20500 gulicolor1">Quri·古力</p>
              </div>
              <div class="alpacacha font12500 gulicolor2">
                <span class="guliback">霸道总裁</span
                ><span class="guliback">多金</span>
              </div>
              <div class="alpacades font14400 black1">
                <p>
                  你好，我叫古力，我是一位绅士，沉稳的霸道总裁，也是财富的象征。
                </p>
              </div>
            </div>
          </div>
          <div
            :class="
              activealpaca == 1
                ? 'alpaca alpacaactive2 alpacafocus2'
                : 'alpaca alpacaactive2'
            "
            @click="changealpaca(1)"
          >
            <div class="alpacahead">
              <img src="./images/miski.png" alt="" />
            </div>
            <div class="alpacaintroduce">
              <div class="alpacaname">
                <p class="font20500 miskicolor1">Miski·蜜思琪</p>
              </div>
              <div class="alpacacha font12500 miskicolor2">
                <span class="miskiback">仙气飘飘</span
                ><span class="miskiback">温柔</span>
                <span class="miskiback">知性</span>
              </div>
              <div class="alpacades font14400 black1">
                <p>你好，我叫蜜思琪，我是一位治愈女神，温柔且仙气飘飘。</p>
              </div>
            </div>
          </div>
          <div
            :class="
              activealpaca == 2
                ? 'alpaca alpacaactive3 alpacafocus3'
                : 'alpaca alpacaactive3'
            "
            @click="changealpaca(2)"
          >
            <div class="alpacahead">
              <img src="./images/sami.png" alt="" />
            </div>
            <div class="alpacaintroduce">
              <div class="alpacaname">
                <p class="font20500 samicolor1">Sami·萨米</p>
              </div>
              <div class="alpacacha font12500 samicolor2">
                <span class="samiback">活泼可爱</span
                ><span class="samiback">乖巧</span>
                <span class="samiback">童真</span>
              </div>
              <div class="alpacades font14400 black1">
                <p>
                  你好，我叫萨米，我是一个小朋友，精灵古怪的我可以给你带来好运气。
                </p>
              </div>
            </div>
          </div>
          <div
            :class="
              activealpaca == 3
                ? 'alpaca alpacaactive4 alpacafocus4'
                : 'alpaca alpacaactive4'
            "
            @click="changealpaca(3)"
          >
            <div class="alpacahead">
              <img src="./images/bonito.png" alt="" />
            </div>
            <div class="alpacaintroduce">
              <div class="alpacaname">
                <p class="font20500 bonitocolor1">Bonito·伯尼托</p>
              </div>
              <div class="alpacacha font12500 bonitocolor2">
                <span class="bonitoback">坚毅勇敢</span
                ><span class="bonitoback">文采斐然</span>
                <span class="bonitoback">理性</span>
              </div>
              <div class="alpacades font14400 black1">
                <p>你好，我叫伯尼托，风流倜傥，英俊潇洒就是我的代名词。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventSource from 'eventsource'
import {
  getStreamId, dochatgpt, gethistory
} from "@/api"
// import { DropdownMenu, DropdownItem, Cell } from 'vant'
// import 'vant/lib/index.css'
export default {
  name: "Partner",
  // components: {
  //   "van-dropdown-item": DropdownItem,
  //   "van-dropdown-menu": DropdownMenu,
  //   "van-cell": Cell


  // },
  data () {
    return {
      mouseactive: 0,
      leftactive: 0,
      drawer: false,
      frontquestion: '',
      questionend: '',
      streamId: '',
      conversationId: '',
      messages: '',
      activealpaca: 0,
      messagesarr: [],
      messindex: 0,
      userInfo: {},
      alpacas: [
        { name: '古力', headimg: require('./images/guli.png') },
        { name: '蜜思琪', headimg: require('./images/miski.png') },
        { name: '萨米', headimg: require('./images/sami.png') },
        { name: '伯尼托', headimg: require('./images/bonito.png') },
      ],
      historyparam: {
        pageNum: 1,
        pageSize: 10,
        chatContent: ''
      },
      historyTotal: 0,
      historyList: [],
      topnav: false,//顶部菜单

    }
  },
  created () {
    this.onStream()
  },
  mounted () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    console.log(this.userInfo, 'this.userInfo')

    if (localStorage.getItem('messagesarr')) {
      this.messagesarr = JSON.parse(localStorage.getItem('messagesarr'))
    }

    //  else {
    //   this.messagesarr.messages.push({ chatRole: 'robot', chatContent: `你好，我是${this.alpacas[this.messagesarr.active].name}，我是你的专属对话机器人。` })
    //   localStorage.setItem('messagesarr', JSON.stringify(this.messagesarr))
    // }

  },
  methods: {
    closeshishiwen () {
      this.frontquestion = ''
    },
    handleCurrentChange (num) {
      this.historyparam.pageNum = num
      this.gethistoryList()
    },
    brightenKeyword (val, keyword) {
      const Reg = new RegExp(keyword, "gi")
      if (val) {
        return val.replace(
          Reg,
          `<span style="color: rgba(67, 207, 124, 1);
">${keyword}</span>`
        )
      }
    },
    gethistoryList () {
      gethistory(this.historyparam).then((d) => {
        console.log(d, 'ddd')
        if (d.code == 200) {
          this.historyList = d.rows
          this.historyTotal = d.total
        }

      })

    },
    changeleft (num) {
      if (num == this.leftactive) {
        this.leftactive = 0
      } else {
        this.leftactive = num
      }
      if (num != 5) this.topnav = false
    },
    changeact (num) {
      this.mouseactive = num
    },
    // handleClose () {

    // },
    changealpaca (index) {
      this.activealpaca = index
      this.leftactive = 0
      this.topnav = false
    },
    changefrontques (valdf) {
      if (this.frontquestion == valdf) {
        this.frontquestion = ''
      } else {
        this.frontquestion = valdf
      }



    },
    // handleIconClick () {
    //   console.log('dfdfxx')
    // },
    createNewHuiHua () {
      this.messagesarr.push({ chatRole: 'xitong', chatContent: '已为您开启新会话' })
    },
    dochatgpt (obj, isstorage = false) {
      // console.log(obj, 'obj')
      if (obj.chatRole == 'user') {
        let arrlength = this.messagesarr.push(obj)
        this.messindex = arrlength
      } else {
        // this.messagesarr[this.messindex] = obj
        this.$set(this.messagesarr, this.messindex, obj)
      }

      // console.log(this.streamId + 'user')
      // if (obj.chatRole == 'user') {
      //   // this.messagesarr[this.streamId + 'user'] = {}
      //   this.messagesarr[this.streamId + 'user'] = obj
      // } else {
      //   // if (!this.messagesarr[this.streamId + 'robot']) this.messagesarr[this.streamId + 'robot'] = {}
      //   this.messagesarr[this.streamId + 'robot'] = obj
      // }
      if (isstorage) {

        dochatgpt(obj).then((d) => {
          // console.log(d, 'role')
        })
        localStorage.setItem('messagesarr', JSON.stringify(this.messagesarr))
      }


    },
    getStreamId () {
      let tempstr = this.questionend.trim()
      if (!tempstr) {
        this.$message.error("请输入内容")
        return
      }
      if (!this.userInfo) {
        this.$router.replace({
          name: "Home",
          params: {
            forword: "",
            showLogin: true
          }

        })
        return
      }
      if (!this.userInfo.vipValidityDate || new Date(this.userInfo.vipValidityDate + ' 24:00:00') < new Date()) {
        // this.$bus.$emit("buyChangeVisible", true)
        this.$bus.$emit("doShowBuy", true)
        return
      }

      let question = this.frontquestion + this.questionend

      getStreamId({ question, conversationId: this.conversationId }).then((d) => {
        if (d.code == 200) {
          this.frontquestion = ''
          this.questionend = ''
          this.streamId = d.streamId
          this.dochatgpt({ chatRole: 'user', chatContent: question }, true)
          this.conversationId = d.conversationId
          // this.onStream()
        }
      })
    },
    onStream () {
      //alert(document.getElementById("streamId").value)
      // console.log("onStream")
      // var content = document.getElementById("content")
      const that = this
      let content = ""
      var source = new EventSource(

        "https://api.tuojin.cc/chat/stream?streamId=" + this.streamId
      )
      //   source.addEventListener('message', function(e) {
      //   let data = JSON.parse(e.data)
      //   this.newmessages = data
      // }, false)

      source.onopen = function () {
        console.log("onopen")
        // this.messages = ''
      }
      source.onmessage = function (event) {
        if (event.type == "close") {

          source.close()
          console.log("close")
          return
        }
        if (event.type == "message") {

          // 
          if (event.data === "") {
            content += ""

          } else {
            content += event.data
              .replace(/\r/, "\n")
              .replace("<c-api-line>", "\n")

          }
          that.messages = content
          that.dochatgpt({ chatRole: 'robot', chatContent: that.messages })
          // console.log("message", that.messages)

        }
      }
      source.onerror = function (event) {
        if (that.messages != '') { that.dochatgpt({ chatRole: 'robot', chatContent: that.messages }, true) }
        console.log("onerror", event)
        source.close()
      }

    },


  },
  watch: {
    streamId (newval, oldval) {
      if (newval != '') {
        this.onStream()
      }

    },
    messagesarr () {
      let ele = this.$refs.dialoguelist
      if (ele.scrollHeight > ele.clientHeight) {
        // setTimeout(function () {
        //设置滚动条到最底部
        ele.scrollTop = ele.scrollHeight + 40
        // ele.style.opacity = 1;
        // }, 500);
      }

    },
  }


}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #eee;
}
/*定义滑块 样式*/
::-webkit-scrollbar-thumb {
  height: 100px; /* 滚动条滑块长度 */
  background-color: #ccc;
}
/deep/.el-pagination {
  text-align: center;
  margin: 60px 0 50px;
}
/deep/.el-tag {
  border: none;
}
/deep/.el-icon-close:before {
  color: white;
}
/deep/.el-icon-close:hover {
  background: none;
}
/deep/.el-pagination.is-background .btn-next,
/deep/.el-pagination.is-background .btn-prev,
/deep/.el-pagination.is-background .el-pager li {
  height: 32px;
  line-height: 32px;
  opacity: 1;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 1);
  backdrop-filter: blur(20px);

  font-size: 14px;
  font-weight: 400;
  color: rgba(119, 119, 119, 1);

  padding: 0 12px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  height: 32px;
  background: rgba(67, 207, 124, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}
/deep/.el-input__inner {
  width: 100%;
  height: 54px;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(20px);

  font-size: 18px;
  font-weight: 400;
  // color: rgba(170, 170, 170, 1);
  color: #000;
}
/deep/.el-textarea__inner {
  width: 100%;
  opacity: 1;
  border-radius: 12px;
  background: rgba(255, 255, 255, 1);
  // backdrop-filter: blur(20px);

  font-size: 18px;
  font-weight: 400;
  color: rgba(170, 170, 170, 1);
}
/deep/ .el-input-group__prepend:focus,
/deep/.el-input__inner:focus {
  border-color: #dcdfe6;
}
.parterpage {
  width: 100%;
  padding: 100px 0 120px;
  display: flex;
  justify-content: center;

  .pleft {
    // width: 252px;
    width: 20%;
    height: 830px;
    border-right: 2px solid rgba(238, 238, 238, 1);
    opacity: 1;
    border-radius: 12px 0px 0px 12px;
    background: rgba(255, 255, 255, 1);
    // padding: 23px 20px;
    .lefttop {
      margin-top: 23px;
      padding: 0 20px;
    }
    .head {
      width: 50px;
      height: 50px;
      margin: 0 auto;
      margin-top: 23px;
    }
    .headtitle {
      text-align: center;
    }
    .navlist {
      margin-top: 380px;
      li {
        width: 100%;
        height: 54px;
        cursor: pointer;
        display: flex;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
      }
      .activeli {
        background: rgba(245, 245, 245, 1);
        color: rgba(67, 207, 124, 1);
        border-left: 4px solid rgba(67, 207, 124, 1);
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  .rightbox {
    width: 80%;
    .topbox {
      display: none;
      position: relative;
    }
    .dialogtitle {
      width: 100%;
      height: 60px;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-bottom: 2px solid rgba(199, 199, 199, 0.25);
      box-shadow: 0px 2px 4px rgba(199, 199, 199, 0.25);
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    .navlist {
      // margin-top: 5px;
      width: 100%;
      // padding: 5px 30px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 60px;
      z-index: 9;
      box-sizing: border-box;
      padding: 0 30px 20px;
      img {
        width: 24px;
        height: 24px;
      }
      li {
        // padding: 0 30px;
        line-height: 2;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }
      .activeli {
        color: rgba(67, 207, 124, 1);
      }
    }
    .topalpacabox {
      // width: 438px;
      .justbetten {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      width: 100%;
      // height: 756px;
      // position: absolute;
      // top: 50%;
      // left: -2px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // transform: translateY(-50%);
      opacity: 1;
      border-radius: 12px;
      background: rgba(245, 245, 247, 1);
      box-shadow: 3px 2px 4px rgba(170, 170, 170, 0.25);

      padding: 12px 5px;
      .alpacaclose {
        width: 100%;
        text-align: right;
        img {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
      .alpaca {
        width: 333.7px;
        height: 119px;
        margin-top: 18px;
        opacity: 1;
        cursor: pointer;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.4);

        border: 2px solid rgba(255, 255, 255, 1);

        backdrop-filter: blur(18px);
        display: flex;
        align-items: center;

        .alpacahead {
          width: 115.65px;
          height: 115.67px;
          border-radius: 8px;
          background: rgba(255, 255, 255, 1);
          text-align: center;
          padding-top: 12px;

          img {
            width: 93.58px;
            height: 93.6px;
            margin: 0 auto;
          }
        }
        .alpacaintroduce {
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: 6px 10px;
          .alpacaname {
            width: 100%;
            padding: 0 0 8px;
            border-bottom: 1px solid rgba(238, 238, 238, 1);
          }
          .alpacacha {
            padding: 8px 0;
            border-bottom: 1px solid rgba(238, 238, 238, 1);
            display: flex;
            span {
              height: 17px;
              line-height: 17px;
              opacity: 1;
              border-radius: 4px;
              margin-right: 8px;

              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              padding: 2px 4px 2px 4px;
            }
            .guliback {
              background: rgba(255, 237, 217, 1);
            }
            .miskiback {
              background: rgba(255, 238, 235, 1);
            }
            .samiback {
              background: rgba(255, 245, 255, 1);
            }
            .bonitoback {
              background: rgba(250, 239, 227, 1);
            }
          }
          .alpacades {
            text-align: justify;
          }
        }
      }
      .alpacaactive1:hover,
      .alpacafocus1 {
        border: 2px solid rgba(229, 136, 71, 1);

        box-shadow: 0px 2px 8px rgba(252, 201, 146, 1);
      }
      .alpacaactive2:hover,
      .alpacafocus2 {
        border: 2px solid rgba(240, 151, 151, 1);

        box-shadow: 0px 2px 8px rgba(250, 194, 182, 1);
      }
      .alpacaactive3:hover,
      .alpacafocus3 {
        border: 2px solid rgba(230, 124, 180, 1);

        box-shadow: 0px 2px 8px rgba(255, 209, 255, 1);
      }
      .alpacaactive4:hover,
      .alpacafocus4 {
        border: 2px solid rgba(209, 141, 59, 1);

        box-shadow: 0px 2px 8px rgba(252, 228, 199, 1);
      }
    }
  }
  .pright {
    // width: 1248px;
    width: 100%;
    height: 830px;
    position: relative;
    // border-left: 2px solid rgba(238, 238, 238, 1);
    opacity: 1;
    border-radius: 0px 12px 12px 0px;
    background: rgba(255, 255, 255, 0.6);

    border: 2px solid rgba(255, 255, 255, 1);
    padding: 40px;

    backdrop-filter: blur(20px);
    .dialoguebox {
      /deep/.el-icon-edit:before {
        content: "";
      }

      /deep/.el-input__icon {
        width: 24px;
        height: 24px;
        margin-top: 15px;
        margin-right: 10px;
        background: url("./images/send.png") no-repeat;
        cursor: pointer;
      }
      /deep/.el-input__iconact {
        width: 24px;
        height: 24px;
        margin-top: 15px;
        margin-right: 10px;
        background: url("./images/sendact.png") no-repeat;
        cursor: pointer;
      }
      /deep/.el-input__inner {
        border-radius: 0 12px 12px 0;
        border-left: none;
      }
      /deep/.el-input-group__prepend {
        background-color: #fff;
        border-radius: 12px 0 0 12px;
        border-color: #dcdfe6;
      }
      /deep/.el-input-group__prepend span {
        display: block;
        // width: 166px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        border-radius: 8px;
        background: rgba(67, 207, 124, 1);
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        // padding: 0px 20px;
      }
    }
    .dialoguelist {
      width: 100%;
      height: 670px;
      overflow-y: auto;
      // padding: 20px 40px;
      .dialogueitem {
        display: flex;
        margin-bottom: 30px;
      }
      .itemrobot {
        justify-content: flex-start;
        .dialoghead {
          margin-right: 10px;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
      .itemuser {
        justify-content: flex-end;
        .dialog {
          // width: 100%;
          margin-right: 10px;
        }
      }
      .dialoghead {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      .xitong {
        width: 148px;
        height: 28px;
        opacity: 1;
        border-radius: 6px;
        background: rgba(255, 255, 255, 1);
        text-align: center;
        line-height: 28px;
        margin: 0 auto;
      }
      .dialog {
        // width: 100%;
        // flex: 1;
        padding: 13px 24px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 1);
      }
    }
    .classlist {
      display: flex;
      span {
        cursor: pointer;
      }
    }
    .classnav {
      display: block;
      height: 35px;
      line-height: 35px;
    }
    .classnavb {
      padding: 0 10px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      margin-right: 20px;
    }
    /deep/.el-input {
      margin-top: 10px;
    }
    .historybox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.95);

      padding: 20px;
      .historyclose {
        width: 100%;
        text-align: right;
        img {
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
      .textcontenbox {
        width: 100%;
        padding: 0 80px;
      }
      /deep/.el-input__inner {
        border: 2px solid rgba(119, 119, 119, 1);
        color: rgba(29, 29, 31, 1);
      }
      /deep/.el-icon-search:before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("./images/search.png") no-repeat;
        margin: 16px 20px;
      }
      /deep/.el-input--prefix .el-input__inner {
        padding-left: 50px;
      }

      .textbox {
        width: 100%;
        height: 700px;
        padding-top: 10px;
        overflow-y: auto;
        .history {
          display: flex;
          padding-bottom: 10px;
          border-bottom: 2px dashed rgba(170, 170, 170, 1);
          margin-top: 10px;

          .historyhead {
            width: 50px;
            height: 50px;
            margin-right: 25px;
            img {
              border-radius: 50%;
            }
          }
          .historyitem {
            flex: 1;
            .itemtitle {
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}
.alpacabox {
  width: 438px;
  height: 756px;
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  opacity: 1;
  border-radius: 0px 12px 12px 0px;
  background: rgba(245, 245, 247, 1);
  box-shadow: 3px 2px 4px rgba(170, 170, 170, 0.25);

  padding: 12px 28px;
  .alpacaclose {
    width: 100%;
    text-align: right;
    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
  .alpaca {
    width: 100%;
    height: 135px;
    margin-top: 38px;
    opacity: 1;
    cursor: pointer;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.4);

    border: 2px solid rgba(255, 255, 255, 1);

    backdrop-filter: blur(18px);
    display: flex;
    align-items: center;

    .alpacahead {
      width: 131px;
      height: 131px;
      opacity: 1;
      border-radius: 8px;
      background: rgba(255, 255, 255, 1);
      text-align: center;
      padding-top: 12px;

      img {
        width: 106px;
        height: 106px;
        margin: 0 auto;
      }
    }
    .alpacaintroduce {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 6px 10px;
      .alpacaname {
        width: 100%;
        padding: 0 0 8px;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
      }
      .alpacacha {
        padding: 8px 0;
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        display: flex;
        span {
          height: 20px;
          line-height: 20px;
          opacity: 1;
          border-radius: 4px;
          margin-right: 8px;

          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 2px 4px 2px 4px;
        }
        .guliback {
          background: rgba(255, 237, 217, 1);
        }
        .miskiback {
          background: rgba(255, 238, 235, 1);
        }
        .samiback {
          background: rgba(255, 245, 255, 1);
        }
        .bonitoback {
          background: rgba(250, 239, 227, 1);
        }
      }
      .alpacades {
        text-align: justify;
      }
    }
  }
  .alpacaactive1:hover,
  .alpacafocus1 {
    border: 2px solid rgba(229, 136, 71, 1);

    box-shadow: 0px 2px 8px rgba(252, 201, 146, 1);
  }
  .alpacaactive2:hover,
  .alpacafocus2 {
    border: 2px solid rgba(240, 151, 151, 1);

    box-shadow: 0px 2px 8px rgba(250, 194, 182, 1);
  }
  .alpacaactive3:hover,
  .alpacafocus3 {
    border: 2px solid rgba(230, 124, 180, 1);

    box-shadow: 0px 2px 8px rgba(255, 209, 255, 1);
  }
  .alpacaactive4:hover,
  .alpacafocus4 {
    border: 2px solid rgba(209, 141, 59, 1);

    box-shadow: 0px 2px 8px rgba(252, 228, 199, 1);
  }
}
@media (max-width: 1116px) {
  .pleft {
    display: none !important;
  }
  .topbox {
    display: block !important;
  }
  .pright {
    border: none !important;
    border-radius: 0px !important;
  }
  .rightbox {
    border-radius: 12px;

    border: 2px solid rgba(255, 255, 255, 1);
  }
}
</style>